.database {
    margin: 0;
}

.database :global(.list) {
    width: 100%;
}

.database :global(.listTable) {
    width: 100%;
    border-collapse: separate;
    border-spacing: 5px;
}

.database :global(.listTable .listIcon),
.database :global(.listTable .listExtra) {
    vertical-align: middle;
    text-align: center;
    width: 65px;
    line-height: 0;
}

.database :global(.itemDetail) {
    border-collapse: separate;
    border-spacing: 1px;
}
.database :global(.itemDetail .head) {
    width: 30%;
}

.database :global(.itemDetail .readme),
.database :global(.itemDetail .rights) {
    background-color: #ffffff;
    width: 100%;
    max-height: 200px;
    overflow: auto;
}

.database :global(.advancedSearch .head) {
    width: 30%;
}

.database :global(.advancedSearch .search) {
    text-align: center;
    margin: 10px;
}

.database :global(.advancedSearch .itemtype) {
    margin: 5px 0;
}

.database :global(.advancedSearch .itemtype .itemtypeName),
.database :global(.advancedSearch .itemtype .itemtypeFields) {
    border-collapse: separate;
    border-spacing: 1px;
}
.database :global(.advancedSearch .itemtype .itemtypeName) {
    margin: 0;
}
.database :global(.advancedSearch .itemtype .itemtypeName input) {
    margin-right: 5px;
}
.database :global(.advancedSearch .itemtype .itemtypeFields) {
    margin: 2px 0;
}
.database :global(.advancedSearch .itemtype .itemtypeName th) {
    padding: 10px;
}

.database :global(.advancedSearch .fieldDateLabel) {
    display: inline-block;
    width: 50px;
}

.database :global(.advancedSearch .fieldDate select),
.database :global(.advancedSearch .fieldDate input) {
    margin: 0 5px 0 0;
}
