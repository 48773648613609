*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  line-height: 1.15;
}

html,
body,
#root {
  width: 100%;
  min-width: 990px;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  color: #333333;
  background-color: #ffffff;
  margin: 0;
  font-size: 14px;
  border-top: 3px solid #2c6bab;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-weight: bold;
  line-height: 1.2;
}

h1 {
  font-size: 1.383rem;
}

h2 {
  font-size: 1.296rem;
}

h3 {
  font-size: 1.215rem;
}

h4 {
  font-size: 1.138rem;
}

h5 {
  font-size: 1.067rem;
}

h6 {
  font-size: 1rem;
}

p {
  margin: 0 0 1rem;
}

a {
  color: #16315c;
}

a:visited {
  color: #5c165b;
}

ul, ol {
  margin: 0 0 1rem;
  padding-left: 1rem;
}

li {
  margin: 0 0 0.5rem;
  padding: 0 0 0 0.5rem;
}

table {
  margin: 0 0 1rem;
  width: 100%;
}

td,
th {
  padding: 0.5rem;
}

td {
  vertical-align: top;
}

.foot {
  vertical-align: middle;
}

th,
.head,
.foot {
  font-weight: bold;
  color: #666666;
  background-color: #eeeeee;
  vertical-align: middle;
}

.even {
  background-color: #f7f7f7;
}

.odd {
  background-color: #fafafa;
}

.col2::after {
  content: "";
  display: block;
  clear: both;
}

.col2 .leftcolumn {
  float: left;
  width: 260px;
  margin: 10px 0;
}

.col2 .centercolumn {
  float: right;
  width: 720px;
}

.centerCcolumn {
  margin: 10px 0;
}

.centerLcolumn {
  display: inline-block;
  vertical-align: top;
  margin: 10px 10px 10px 0;
  width: calc(50% - 10px);
}

.centerRcolumn {
  display: inline-block;
  vertical-align: top;
  margin: 10px 0 10px 10px;
  width: calc(50% - 10px);
}

.centerLcolumn .block:not(:first-child),
.centerRcolumn .block:not(:first-child) {
  margin-top: 20px;
}

.hidden {
  display: none;
}

.block .blockTitle {
  font-weight: bold;
  color: #ffffff;
  background: url(images/blockTitleBack.png) no-repeat left center;
  text-shadow: 0px -1px 0 #000000;
}

.centercolumn .block {
  border: 1px solid#cccccc;
}

.leftcolumn .block:not(:first-child) {
  margin: 10px 0;
}

.leftcolumn .blockTitle,
.centerCcolumn .blockTitle {
  padding: 0.75rem 0.75rem 0.75rem 1.5rem;
}

.centerRcolumn .blockTitle,
.centerLcolumn .blockTitle {
  margin: 8px 10px 0;
  padding: 0.5rem 0.5rem 0.5rem 1.5rem;
}

.centerCcolumn .blockContent,
.centerRcolumn .blockContent,
.centerLcolumn .blockContent {
  margin: 0;
  padding: 10px;
}

#page {
  margin: 0 auto;
  width: 990px;
}

#header {
  margin: 0;
  width: 990px;
  min-width: 990px;
}

#header a {
  color: #333333;
}

#header .top {
  height: 90px;
}

#header .top::after {
  clear: both;
  content: '';
  display: block;
}

#header .logo {
  float: left;
  display: block;
  margin: 10px 0;
  height: 70px;
  width: 400px;
  background-image: url(images/logo.png);
}

#header .utility {
  float: right;
  display: block;
  height: 90px;
  width: 590px;
  text-align: right;
}

#header .utility .socialmedia {
  display: inline-block;
  position: relative;
  width: 126px;
  height: 35px;
  top: 20px;
  right: 50px;
}

#header .utility .socialmedia a {
  padding: 0 5px;
}

#header .utility .sitemap {
  display: inline-block;
  position: relative;
  top: 20px;
  left: 80px;
}

#header .utility .incf {
  display: inline-block;
  position: relative;
  width: 120px;
  height: 68px;
  top: 5px;
}

#header .utility .language {
  display: inline-block;
  position: relative;
  width: 80px;
  top: 15px;
  right: -115px;
}

#header .utility .language a {
  display: inline-block;
  font-size: 12px;
  text-indent: 10px;
  background: url(./images/indent.png) no-repeat left center;
}

#header .menubar {
  height: 40px;
  background: linear-gradient(#fff, #eee);
}

#header .menubar::after {
  content: "";
  display: block;
  clear: both;
}

#header .menubar .mainmenu {
  float: left;
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-size: 90%;
}

#header .menubar .mainmenu>li {
  display: inline-block;
  border-right: 2px solid #bbcdd5;
  text-align: center;
}

#header .menubar .mainmenu li:hover {
  background: linear-gradient(#fff, #d9e5fe);
  cursor: pointer;
}

#header .menubar .mainmenu li>a,
#header .menubar .mainmenu li>span {
  display: block;
  margin: 0 10px;
  text-decoration: none;
  color: #333333;
  text-shadow: 0px 1px #FFF;
}

#header .menubar .mainmenu>li {
  line-height: 39px;
  height: 40px;
  margin: 0;
  padding: 0;
}

#header .menubar .mainmenu .submenu {
  display: block;
  position: absolute;
  width: 200px;
  text-align: left;
  border: 1px solid #cccccc;
  animation: fadeIn 1s ease 0s 1 normal;
  font-size: 90%;
  z-index: 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

#header .menubar .mainmenu .submenu .item {
  display: block;
  line-height: 1.5;
  padding: 5px 10px;
  text-decoration: none;
  background: linear-gradient(#fff, #eee);
}

#header .menubar .mainmenu .submenu .item:not(:first-child) {
  border-top: 1px solid #cccccc;
}

#header .menubar .mainmenu .submenu .item:hover {
  background: linear-gradient(#fff, #d9e5fe);
}

#header .menubar .search_box {
  float: right;
  margin: 10px 15px 0 0;
}

#header .menubar .search_box input {
  margin: 0 1px;
  padding: 2px;
  height: 20px;
  width: 150px;
  border: 1px #ddd solid;
  line-height: normal;
  vertical-align: top;
}

#header .menubar .search_box a {
  line-height: 0;
  font-size: 0;
}

#footer {
  text-align: center;
}

#footer .banners {
  margin: 0;
  padding: 0;
  height: 60px;
  list-style-type: none;
  line-height: 60px;
}

#footer .banners>li {
  margin: 0 10px;
  display: inline-block;
}

#footer .banners>li img {
  vertical-align: middle;
}

#footer .banners .credits {
  color: white;
  background: #0174DF;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 20px;
  text-decoration: none;
}

#footer .copyright {
  line-height: 38px;
  display: block;
  font-size: 90%;
}

#main {
  margin: 10px 0;
  background-color: #ffffff;
  min-height: 500px;
  font-size: 90%;
}

#main .goPageTop {
  text-align: right;
  font-size: 80%;
}

#main .goPageTop a {
  display: inline-block;
  line-height: 2;
  padding: 0 20px;
  background: url(./images/go-page-top.png) no-repeat;
  text-decoration: none;
  color: #333333;
}

#main .goPageTop a:hover {
  text-decoration: none;
  color: #333333;
}

#main h1 {
  font-weight: bold;
  padding: .3rem .3rem .3rem 2.25rem;
  background: url(images/h1-back.png) no-repeat left center;
  border: 1px solid #cccccc;
  color: white;
  text-shadow: 0px -1px 0 #000;
}

#main h2 {
  font-weight: bold;
  padding: .3rem .3rem .3rem 2.25rem;
  background: url(images/h2-back.png) no-repeat left center;
  border: 1px solid #cccccc;
  text-shadow: 0px -1px 0 #FFF;
}

#main h3 {
  position: relative;
  padding: .2rem .2rem .2rem .75rem;
  border-left: 6px solid #ccc;
  border-bottom: 1px solid #ccc;
}

#main .pico_body {
  margin: 0;
  padding: 0;
}

#link-banners div {
  margin-bottom: 8px;
  font-size: 0;
}

.feed {
  margin: 0 0 10px;
}

.feed .pubDate {
  color: #797c80;
  margin: 0 5px 0;
  font-size: 90%;
  font-weight: bold;
  font-style: italic;
}

.feed .pubDate .new {
  margin-left: 3px;
  font-weight: bold;
  color: #ff9999;
}

.feed .logo {
  display: inline-block;
  width: 60px !important;
  overflow: hidden;
  margin: 2px 10px;
}

.feed .item {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 80px);
}

.feed .item .site {
  margin: 2px 0 0;
  font-size: 85%;
  font-style: italic;
}

/*
.pico_body table {
  border-collapse: collapse;
  font-size: 0.9rem;
}

.pico_body table caption {
  font-weight: bold;
  font-size: 1.2rem;
}

.pico_body table,
.pico_body th,
.pico_body td {
  border: 1px solid black;
  padding: 5px;
}
*/

.bulletin {
  margin: 10px 5px;
}

.bulletin .itemHead {
  margin: 10px 0 5px;
  padding: 2px 0;
  font-weight: bold;
  border-bottom: 1px dotted #CCC;
}

.bulletin .itemInfo {
  text-align: right;
  margin: 5px 0;
  padding: 3px 0;
  font-size: 90%;
}

.bulletin .itemTitle {
  font-size: 120%;
  font-weight: bold;
}

.bulletin .itemTitle a {
  text-decoration: none;
}

.bulletin .itemPoster {
  font-size: 90%;
}

.bulletin .itemPostDate {
  font-size: 90%;
}

.bulletin .itemStats {
  font-size: 90%;
}

.bulletin .itemBody {
  padding: 0;
  overflow: hidden;
}

.bulletin .itemText {
  margin: 5px 0;
  line-height: 1.47em;
  letter-spacing: 0.04em;
  font-size: 94%;
}

.bulletin .itemFoot {
  text-align: right;
  padding: 2px 0;
  border-top: 2px dotted #999;
}

.bulletin .itemPermaLink {
  clear: both;
  margin: 5px 0;
  font-size: 90%;
}

.bulletin .pagenavi {
  margin: 5px 0;
  text-align: right;
  font-size: 95%;
}

.bulletin .pagenavi .pages {
  margin-left: 10px;
  font-weight: bold;
}

.bulletin .pagenavi .pages .current:before {
  content: '(';
}

.bulletin .pagenavi .pages .current:after {
  content: ')';
}

.platform-committee th:first-child {
  width: 120px;
}

.platform-committee th:nth-child(2) {
  width: 200px;
}

.xoonips .listTable table {
  margin: 0;
}

.xoonips .listTable table > tbody > tr > td {
  padding: 0;
}

.xoonips .listTable .listBody div {
  margin: 1px 0;
}
.xoonips .listTable .listBody .journal .journal_name {
  font-style: italic;
}

.xoonips .itemDetail {
  max-width: 720px;
}
.xoonips .itemDetail > tbody > tr > td:first-child {
  width: 200px;
}
.xoonips .itemDetail > tbody > tr > td:nth-child(2) {
  width: 520px;
  max-width: 520px;
}

.xoonips .itemDetail table {
  margin: 5px 0;
}

.xoonips .itemDetail table td,
.xoonips .itemDetail table th {
  padding: 5px 0;
}

.xoonips .itemDetail table td:first-child {
  white-space: nowrap;
}
