.itemTypes .itemType {
    width: 50%;
    padding: 5px;
}

.itemTypes .itemType :global(table) {
    width: 100%;
    margin: 0;
}

.itemTypes .itemType :global(table .itemTypeIcon) {
    width: 50px;
    padding: 5px;
}
.itemTypes .itemType :global(table .itemTypeName) {
    padding: 5px;
    vertical-align: middle;
    font-size: large;
}